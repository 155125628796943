import axios from 'axios';

let token = localStorage.getItem('token');

export default axios.create({
    // baseURL: 'https://api-homologacao.minus.eco.br/api/Heineken',
    // baseURL: 'https://api-valid.minus.eco.br/api/Heineken',
    baseURL: process.env.VUE_APP_BASE_HTTP,
    headers: { Accept: 'application/json', authorization: 'Bearer ' + token },
});
