const produtosStore = {
    state() {
        return {
            todosProdutos: null,
        };
    },
    mutations: {
        setTodosProdutos(state, payload) {
            state.todosProdutos = payload;
        },
    },
    actions: {
        setTodosProdutos(context, payload) {
            context.commit('setTodosProdutos', payload);
        },
    },
    getters: {
        getTodosProdutos(state) {
            return state.todosProdutos;
        },
        getMarcas(state) {
            let tarefas = state.todosProdutos;

            return tarefas.reduce(function (key, element) {
                key[element.marca] = key[element.marca] || [];
                key[element.marca].push(element);
                return key;
            }, Object.create(null));
        },
    },
};

export default produtosStore;
