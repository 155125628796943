import { createRouter, createWebHistory } from 'vue-router';

const TelaCarregamento = () => import('../views/TelaCarregamento.vue');
const TelaLogin = () => import('../views/TelaLogin.vue');
const TelaMenu = () => import('../views/TelaMenu.vue');
const AlertValidade = () => import('../views/AlertValidade.vue');
const VerificacaoProdutos = () => import('../views/VerificacaoProdutos.vue');
const TelaProdutos = () => import('../views/TelaProdutos.vue');
const IncluirProdutos = () => import('../views/IncluirProdutos.vue');
const ProdutosDescricao = () => import('../views/ProdutosDescricao.vue');
const ProdutosEdit = () => import('../views/ProdutosEdit.vue');
const TrocarLoja = () => import('../views/TrocarLoja.vue');

const routes = [
    {
        path: '/',
        name: 'login',
        component: TelaLogin,
        meta: {
            title: 'semHeader',
            hasArrow: false,
            hasNoRefresh: true,
            isLogin: true,
        },
    },
    {
        path: '/carregamento',
        name: 'carregamento',
        component: TelaCarregamento,
        meta: { title: 'semHeader', hasArrow: false, hasNoRefresh: true },
    },
    {
        path: '/trocar.loja',
        name: 'trocar.loja',
        component: TrocarLoja,
        meta: { title: 'semHeader', hasArrow: false, hasNoRefresh: true },
    },
    {
        path: '/menu',
        name: 'menu',
        component: TelaMenu,
        meta: {
            title: 'Menu',
            hasArrow: false,
            hasNoRefresh: false,
        },
    },
    {
        path: '/alertValidade',
        name: 'alertValidade',
        component: AlertValidade,
        meta: {
            title: 'Menu',
            hasArrow: true,
            hasNoRefresh: false,
        },
    },
    {
        path: '/verificacao.produtos',
        name: 'verificacao.produtos',
        component: VerificacaoProdutos,
        meta: { title: 'Detalhes', hasArrow: true, hasNoRefresh: true, isSave: true },
    },
    {
        path: '/produtos',
        name: 'produtos',
        component: TelaProdutos,
        meta: { title: 'Menu', hasArrow: true, hasNoRefresh: false },
    },
    {
        path: '/produtos/descricao',
        name: 'produtos.descricao',
        component: ProdutosDescricao,
        meta: { title: 'Inclusão de Produto', hasArrow: true, hasNoRefresh: false, isProduto: true, isAlerta: false },
    },
    {
        path: '/produtos/edit',
        name: 'produtos.edit',
        component: ProdutosEdit,
        meta: { title: 'Inclusão de Produto', hasArrow: true, hasNoRefresh: true, isSave: true },
    },
    {
        path: '/incluirProdutos',
        name: 'incluirProdutos',
        component: IncluirProdutos,
        meta: { title: 'Inclusão de Produto', hasArrow: true, hasNoRefresh: true, isSave: true },
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0 };
        }
    },
});

router.beforeEach((to, from, next) => {
    let currentRoute = to.name;

    if (currentRoute !== 'login') {
        let user = sessionStorage.getItem('usuario');

        if (user) {
            next();
        } else {
            router.push({ name: 'login' });
        }
    } else {
        next();
    }
});

export default router;
