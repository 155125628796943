import { createStore } from 'vuex';
import inclusaoStore from './inclusao';
import tarefaStore from './tarefas';
import produtosStore from './produtos';

const store = createStore({
    modules: {
        inclusao: inclusaoStore,
        tarefas: tarefaStore,
        produtos: produtosStore,
    },
    state() {
        return {
            todosProdutos: null,
            codigoInclusao: null,
            numeroSync: 0,
            sugestao: null,
        };
    },
    mutations: {
        setSugestao(state, payload) {
            state.sugestao = payload;
        },
        setCodigoInclusao(state, payload) {
            state.codigoInclusao = payload;
        },
        plusNumeroSync(state) {
            state.numeroSync++;
        },
        minusNumeroSync(state) {
            state.numeroSync--;
        },
        resetNumeroSync(state) {
            state.numeroSync = 0;
        },
    },
    actions: {
        setSugestao(context, payload) {
            context.commit('setSugestao', payload);
        },
        setCodigoInclusao(context, payload) {
            context.commit('setCodigoInclusao', payload);
        },
        plusNumeroSync(context) {
            context.commit('plusNumeroSync');
        },
        minusNumeroSync(context) {
            context.commit('minusNumeroSync');
        },
        resetNumeroSync(context) {
            context.commit('resetNumeroSync');
        },
    },
    getters: {
        getNumerosSync(state) {
            let countEdit = JSON.parse(localStorage.getItem('inclusaoEdit'));
            let countControle = JSON.parse(localStorage.getItem('controles'));
            let countInclusao = JSON.parse(localStorage.getItem('inclusao'));

            let numerosDivida = 0;

            if (countEdit) {
                numerosDivida += countEdit.length;
            }
            if (countControle) {
                numerosDivida += countControle.length;
            }
            if (countInclusao) {
                numerosDivida += countInclusao.length;
            }

            let contagemFinal = numerosDivida + state.numeroSync - state.numeroSync;

            return contagemFinal;
        },
        getSugestao(state) {
            return state.sugestao;
        },
        getCodigoInclusao(state) {
            return state.codigoInclusao;
        },
    },
});

export default store;
